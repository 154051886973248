@tailwind base;
@tailwind components;
@tailwind utilities;
p,
a,
text,
span,
tspan,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Titillium Web", sans-serif;
    font-size: 12px;
}

body {
    margin: 0;
    background-color: rgb(22 22 22);
}

header {
    animation: fadeIn 0.3s ease-in;
    color: rgb(255, 255, 255);
    height: 70px;
}

header h1 {
    font-size: 26px;
    min-height: 36px;
    margin-top: 1rem;
}

header h2 {
    font-size: 14px;
    min-height: 21px;
}

.node {
    fill: white;
}

.link {
    pointer-events: none;
}

path {
    transition: opacity 0.5s ease-in;
    stroke-opacity: 0.5;
    animation: pulsing 4s cubic-bezier(1, -0.1, 0.9, 0.16) 1s infinite alternate;
}

#radialMenu {
    width: 100vw;
    height: calc(100vh - 70px);
    display: flex;
    position: relative;
}

svg {
    fill: none;
}

.rootG {
    transform-origin: center center;
    transform-box: fill-box;
    position: absolute;
}

.mainG {
    transition: all 1s ease-out;
}

text {
    transition: transform 0.6s ease-out;
    cursor: pointer;
    opacity: 0.8;
}

.previewBox {
    position: absolute;
    width: 10rem;
    height: 10rem;
    background-color: aliceblue;
}

.href {
    fill: rgb(202 230 255);
    opacity: 1 !important;
}

.href:hover {
    text-decoration: underline;
}

#descriptionBox {
    animation: fadeIn 0.3s ease-in-out;
    transition: opacity 0.5s ease-out;
    position: absolute;
    top: -30px;
    left: 10%;
    max-width: 100vw;
    background-color: rgb(15 15 15 / 0%);
    color: #ffffffd1;
    width: 85%;
}

#descriptionBox p {
    font-size: 14px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pulsing {
    from {
        stroke: rgb(70, 130, 180);
    }
    to {
        stroke: rgb(90, 157, 211);
    }
}

@media screen and (max-width: 639px) {
    #descriptionBox {
        text-align: center;
    }
    #descriptionBox p {
        font-size: 12px;
    }
}

@media screen and (max-width: 420px) {
    p,
    a,
    text,
    span,
    tspan,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 11px;
    }
    #descriptionBox {
        width: 100%;
        padding: 0 10px;
        top: 0%;
        left: 0%;
        max-width: none;
    }
    #descriptionBox p {
        font-size: 11px;
    }
}